<section class="triggers-component">
  <section
    class="triggers-title h-14 flex justify-between items-center bg-secondary-50 pl-8 pr-6"
  >
    <p class="tracking-tight text-base text-primary-300">TRIGGERS</p>
  </section>

  <mat-accordion class="triggers-accordion" multi>
    <!-- Button Triggers.-->
    <mat-expansion-panel
      [id]="triggerButtonsId"
      [attr.data-testid]="triggerButtonsId"
      hideToggle
      [expanded]="expansionPanelStates['buttonPanel']"
      (opened)="buttonPanelExpanded = true"
      (closed)="!(buttonPanelExpanded = false)"
      [ngClass]="{
        'pointer-events-none': !loadedButtonTriggers
      }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            class="title-panel"
            [ngClass]="{
              'text-secondary-100': !loadedButtonTriggers
            }"
          >
            <i class="fa fa-light fa-hand-pointer fa-lg"></i>
            <span class="text-sm font-semibold">Buttons Triggers</span>
            <div class="counter-square">
              <span>{{ buttonTriggerNumber }}</span>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <i
            class="fa-light text-2xl"
            [ngClass]="buttonPanelExpanded ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-container
        *ngTemplateOutlet="
          loadedButtonTriggers
            ? buttonTriggersContent
            : triggersLoadingIndicator
        "
      />
    </mat-expansion-panel>

    <!-- Station Triggers.-->
    <mat-expansion-panel
      [id]="stationTriggersId()"
      [attr.data-testid]="stationTriggersId()"
      hideToggle
      [expanded]="expansionPanelStates['stationPanel']"
      (opened)="stationPanelExpanded = true"
      (closed)="!(stationPanelExpanded = false)"
      [ngClass]="{
        'pointer-events-none': !loadedButtonTriggers
      }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            class="title-panel"
            [ngClass]="{
              'text-secondary-100': !loadedButtonTriggers
            }"
          >
            <i class="fak fa-station fa-lg"></i>
            <span class="text-sm font-semibold"
              >{{ termsGeneric.Station.Single }} Triggers</span
            >
            <div class="counter-square">
              <span>{{ stationTriggerNumber }}</span>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <i
            class="fa-light text-2xl"
            [ngClass]="stationPanelExpanded ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-container *ngTemplateOutlet="stationTriggersContent" />
    </mat-expansion-panel>

    <!-- GitHub Triggers. -->
    <mat-expansion-panel
      [id]="githubTriggersId()"
      [attr.data-testid]="githubTriggersId()"
      hideToggle
      [expanded]="expansionPanelStates['githubPanel']"
      (opened)="githubPanelExpanded = true"
      (closed)="!(githubPanelExpanded = false)"
      [ngClass]="{
        'pointer-events-none': !loadedButtonTriggers
      }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            class="title-panel"
            [ngClass]="{
              'text-secondary-100': !loadedButtonTriggers
            }"
          >
            <i class="fa-brands fa-lg fa-github"></i>
            <span class="text-sm font-semibold"> Github Triggers </span>
            <div class="counter-square">
              <span>{{ githubTriggerNumber }}</span>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <i
            class="fa-light text-2xl"
            [ngClass]="githubPanelExpanded ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="githubTriggersContent" />
    </mat-expansion-panel>

    <!-- Scheduled Triggers. -->
    <mat-expansion-panel
      [id]="scheduleTriggersId()"
      [attr.data-testid]="scheduleTriggersId()"
      hideToggle
      [expanded]="
        scheduledPanelExpanded || expansionPanelStates['scheduledPanel']
      "
      (opened)="scheduledPanelExpanded = true"
      (closed)="!(scheduledPanelExpanded = false)"
      [ngClass]="{
        'pointer-events-none': !loadedButtonTriggers
      }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            class="title-panel"
            [ngClass]="{
              'text-secondary-100': !loadedButtonTriggers
            }"
          >
            <i class="fa-light fa-lg fa-calendar"></i>
            <span class="text-sm font-semibold"> Scheduled Triggers </span>
            <div class="counter-square">
              <span>{{ scheduleTriggerNumber }}</span>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <i
            class="fa-light text-2xl"
            [ngClass]="scheduledPanelExpanded ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="scheduledTriggersContent" />
    </mat-expansion-panel>
    <!-- Field Triggers. -->
    @if (showUpdateFieldTrigger) {
      <mat-expansion-panel
        [id]="fieldUpdatesTrigger()"
        [attr.data-testid]="fieldUpdatesTrigger()"
        hideToggle
        [expanded]="fieldPanelExpanded || expansionPanelStates['fieldPanel']"
        (opened)="fieldPanelExpanded = true"
        (closed)="!(fieldPanelExpanded = false)"
        [ngClass]="{
          'pointer-events-none': !loadedButtonTriggers
        }"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div
              class="title-panel"
              [ngClass]="{
                'text-secondary-100': !loadedButtonTriggers
              }"
            >
              <i class="fa-light fa-lg fa-pen-field"></i>
              <span class="text-sm font-semibold">Field Triggers</span>
              <div class="counter-square">
                <span>{{ fieldTriggerNumber }}</span>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <i
              class="fa-light text-2xl"
              [ngClass]="fieldPanelExpanded ? 'fa-angle-up' : 'fa-angle-down'"
            ></i>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-container [ngTemplateOutlet]="fieldUpdateSection" />
      </mat-expansion-panel>
    }
  </mat-accordion>
</section>

<!-- Contents of the buttons triggers section. -->
<ng-template #buttonTriggersContent>
  <section class="button-triggers-section">
    <article
      class="trigger-item-row"
      [id]="
        triggersId[triggerType.ManualFlow]
          ? triggersId[triggerType.ManualFlow].rithmId
          : ''
      "
      [attr.data-testid]="
        triggersId[triggerType.ManualFlow]
          ? triggersId[triggerType.ManualFlow].rithmId
          : ''
      "
    >
      <span> {{ flowButtonName }}</span>
      <div class="button-slide-section">
        <span class="font-normal text-sm text-center"> on click</span>
        <mat-slide-toggle
          id="toggle-trigger-manual-flow"
          data-testid="toggle-trigger-manual-flow"
          [checked]="checkedTrigger(triggerType.ManualFlow)"
          (change)="handleTrigger(triggerType.ManualFlow, $event.checked)"
        />
      </div>
    </article>
    <article
      class="trigger-item-row"
      [id]="
        triggersId[triggerType.Save] ? triggersId[triggerType.Save].rithmId : ''
      "
      [attr.data-testid]="
        triggersId[triggerType.Save] ? triggersId[triggerType.Save].rithmId : ''
      "
    >
      <span> Save</span>
      <div class="button-slide-section">
        <span class="font-normal text-sm text-center"> on click</span>
        <mat-slide-toggle
          id="toggle-trigger-save"
          data-testid="toggle-trigger-save"
          [checked]="checkedTrigger(triggerType.Save)"
          (change)="handleTrigger(triggerType.Save, $event.checked)"
        />
      </div>
    </article>
    @for (button of stationButtons(); track $index) {
      <article
        class="trigger-item-row"
        [id]="button.rithmId"
        [attr.data-testid]="button.rithmId"
      >
        <span> {{ button.label }}</span>
        <div class="button-slide-section">
          <span>on click</span>
          <mat-slide-toggle
            [id]="'toggle-trigger-button-' + button.rithmId"
            [attr.data-testid]="'toggle-trigger-button-' + button.rithmId"
            [checked]="checkedTrigger(triggerType.Button, button.rithmId)"
            (change)="
              handleTrigger(triggerType.Button, $event.checked, button.rithmId)
            "
          />
        </div>
      </article>
    }
  </section>
</ng-template>

<!-- Contents of the station triggers section. -->
<ng-template #stationTriggersContent>
  <section class="station-triggers-section">
    <article
      class="trigger-item-row"
      [id]="
        triggersId[triggerType.ContainerCreated]
          ? triggersId[triggerType.ContainerCreated].rithmId
          : ''
      "
      [attr.data-testid]="
        triggersId[triggerType.ContainerCreated]
          ? triggersId[triggerType.ContainerCreated].rithmId
          : ''
      "
    >
      <span> {{ termsGeneric.Container.Single }} Created</span>
      <div class="button-slide-section">
        <mat-slide-toggle
          id="toggle-create-container"
          data-testid="toggle-create-container"
          [checked]="checkedTrigger(triggerType.ContainerCreated)"
          (change)="handleTrigger(triggerType.ContainerCreated, $event.checked)"
        />
      </div>
    </article>
    <article
      class="trigger-item-row"
      [id]="
        triggersId[triggerType.ContainerArrived]
          ? triggersId[triggerType.ContainerArrived].rithmId
          : ''
      "
      [attr.data-testid]="
        triggersId[triggerType.ContainerArrived]
          ? triggersId[triggerType.ContainerArrived].rithmId
          : ''
      "
    >
      <span>
        Check this {{ termsGeneric.Container.Single.toLowerCase() }} on
        arrival</span
      >
      <div class="button-slide-section">
        <mat-slide-toggle
          id="toggle-check-container"
          data-testid="toggle-check-container"
          [checked]="checkedTrigger(triggerType.ContainerArrived)"
          (change)="handleTrigger(triggerType.ContainerArrived, $event.checked)"
        />
      </div>
    </article>
    <article
      class="trigger-item-row"
      [id]="
        triggersId[triggerType.AnyContainerArrived]
          ? triggersId[triggerType.AnyContainerArrived].rithmId
          : ''
      "
      [attr.data-testid]="
        triggersId[triggerType.AnyContainerArrived]
          ? triggersId[triggerType.AnyContainerArrived].rithmId
          : ''
      "
    >
      <span>
        Check every other
        {{ termsGeneric.Container.Single.toLowerCase() }} on this
        {{ termsGeneric.Container.Plural.toLowerCase() }} arrival</span
      >
      <div class="button-slide-section">
        <mat-slide-toggle
          id="toggle-check-all-container"
          data-testid="toggle-check-all-container"
          [checked]="checkedTrigger(triggerType.AnyContainerArrived)"
          (change)="
            handleTrigger(triggerType.AnyContainerArrived, $event.checked)
          "
        />
      </div>
    </article>
  </section>
</ng-template>

<!-- Content of the trigger schedule section. -->
<ng-template #scheduledTriggersContent>
  <app-scheduled-trigger
    [scheduledTriggers]="scheduledTriggers"
    [bucketQuestions]="bucketQuestions"
    (handleScheduledTrigger)="handleScheduleTrigger.emit($event)"
    (changeTriggerStatus)="
      handleTrigger(triggerType.CronExpression, $event.status, $event.triggerId)
    "
  />
</ng-template>

<!-- Field Update Section. -->
<ng-template #fieldUpdateSection>
  <app-field-update-trigger
    [fieldUpdateTriggers]="fieldUpdateTriggers"
    [bucketQuestions]="bucketQuestions"
    (updateDataInFieldTrigger)="
      updateValueToTrigger(
        $event.questionSelected,
        $event.triggerSelected,
        triggerType.FieldUpdated,
        $event.validChange
      )
    "
    (updateFieldTrigger)="
      handleTrigger(
        triggerType.FieldUpdated,
        $event.isDisabled,
        $event.triggerSelected,
        $event.toggle,
        $event.validChange
      )
    "
  />
  <ng-container [ngTemplateOutlet]="btnNewTrigger" />
</ng-template>

<!-- Content of the trigger github section. -->
<ng-template #githubTriggersContent>
  <section
    class="custom-id-selection flex justify-between items-center border-b border-primary-100 py-2"
  >
    <p class="tracking-tight text-sm">
      Custom ID*
      <i
        class="fal fa-circle-question text-secondary-200 text-xl ml-1"
        [matTooltip]="
          'GitHub actions require a custom ID to know through what ID is the ' +
          termsGeneric.Container.Single.toLowerCase() +
          ' identified.'
        "
        [matTooltipPosition]="'right'"
      ></i>
    </p>

    @if (customIdComponents.length) {
      <div class="custom-id-select-dropdown flex items-center space-x-2">
        <mat-form-field subscriptSizing="dynamic" class="bg-white">
          <mat-label class="text-base">Select ID*</mat-label>
          <mat-select
            id="select-custom-id"
            data-testid="select-custom-id"
            [(ngModel)]="githubIdSelected"
            (selectionChange)="setGithubTriggerId($event.value)"
          >
            @for (id of customIdComponents; track $index) {
              <mat-option
                [id]="'option-custom-id' + id.rithmId"
                [attr.data-testid]="'option-custom-id' + id.rithmId"
                [value]="id.rithmId"
              >
                {{ id.prompt }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    } @else {
      <ng-container *ngTemplateOutlet="addCustomIdButton" />
    }

    <ng-template #addCustomIdButton>
      <div
        [matTooltip]="
          (!customIdComponents.length &&
            'No custom-id components have been found in the bucket.') ||
          ''
        "
      >
        <button
          type="button"
          mat-stroked-button
          color="accent"
          data-testid="add-id-button"
          id="add-id-button"
          (click)="addCustomId()"
        >
          ADD ID
        </button>
      </div>
    </ng-template>
  </section>
  <section class="github-triggers-section flex flex-col">
    @for (item of githubTriggers; track $index) {
      <div
        class="pull-merge station-trigger"
        [id]="triggersId[item.type] ? triggersId[item.type].rithmId : ''"
        [attr.data-testid]="
          triggersId[item.type] ? triggersId[item.type].rithmId : ''
        "
      >
        <span class="font-medium text-base text-primary-400">
          {{ item.label }}</span
        >
        <mat-slide-toggle
          id="toggle-pull-merge"
          data-testid="toggle-pull-merge"
          [disabled]="!githubIdSelected.length"
          [checked]="checkedTrigger(item.type)"
          (change)="handleTrigger(item.type, $event.checked)"
        />
      </div>
    }
  </section>
</ng-template>

<!-- Button to add new trigger.-->
<ng-template #btnNewTrigger>
  <div class="w-[150px] pb-4">
    <button
      type="button"
      mat-stroked-button
      id="add-trigger"
      data-testid="add-trigger"
      (click)="handleTrigger(triggerType.FieldUpdated, true, '')"
    >
      <span class="font-semibold text-sky-500">ADD TRIGGER</span>
    </button>
  </div>
</ng-template>

<!-- Buttons loading indicator. -->
<ng-template #triggersLoadingIndicator>
  <div class="flex h-12 justify-center items-center">
    <app-loading-indicator [diameter]="20" />
  </div>
</ng-template>
