<form [formGroup]="rithmAiForm" class="w-[621px]">
  <h6 class="text-gray-800 text-lg font-semibold">RithmAI</h6>

  <!-- Model -->
  <section class="sub-section">
    <ng-container
      [ngTemplateOutletContext]="{
        title: 'Select an AI model',
        subtitle: 'Select the RithmAI that best suits your needs.'
      }"
      [ngTemplateOutlet]="headerSection"
    />
    <app-select-ai-model
      [selectedModelInput]="preselectedModel() || selectedModelInput()"
      (selectedModelOutput)="rithmAiForm.controls.model.setValue($event)"
    />
  </section>

  @if (rithmAiForm.controls.model.value === actionType.ImageGenerator) {
    <ng-container [ngTemplateOutlet]="imageSettingsSection" />
  }

  <!-- Response Field. -->
  <section class="sub-section">
    <ng-container
      [ngTemplateOutletContext]="{
        title: 'Response field',
        subtitle: 'Select the field you’d like to populate with the response.'
      }"
      [ngTemplateOutlet]="headerSection"
    />
    <mat-form-field
      appearance="outline"
      class="w-full"
      subscriptSizing="dynamic"
      [matTooltip]="
        rithmAiForm.controls.model.value === actionType.ImageGenerator
          ? 'No file upload fields found in Station Bucket'
          : ''
      "
      [matTooltipDisabled]="
        !!responseFieldOptions.fields.length ||
        !!responseFieldOptions.containerInfo.length
      "
      matTooltipClass="tooltip-ai-form"
      matTooltipPosition="above"
    >
      <mat-label class="text-base">Response Field</mat-label>
      <mat-select formControlName="responseField">
        <app-ngx-search
          [multipleQuestions]="[
            responseFieldOptions.containerInfo,
            responseFieldOptions.fields
          ]"
          (getFilteredArrays)="fieldDataFiltered = $event"
        />
        @if (
          fieldDataFiltered.multipleQuestions &&
          fieldDataFiltered.multipleQuestions[0].length
        ) {
          <mat-optgroup
            [label]="termsGeneric.Container.Single + ' Info'"
            class="custom-select"
          >
            @for (
              question of fieldDataFiltered.multipleQuestions
                ? fieldDataFiltered.multipleQuestions[0]
                : [];
              track question.rithmId
            ) {
              <mat-option
                [value]="
                  stationFieldPrefix.ContainerInfoItem + question.questionType
                "
              >
                <ng-container
                  [ngTemplateOutletContext]="{
                    question: question
                  }"
                  [ngTemplateOutlet]="selectOption"
                />
              </mat-option>
            }
          </mat-optgroup>
        }
        @if (
          fieldDataFiltered.multipleQuestions &&
          fieldDataFiltered.multipleQuestions[1].length
        ) {
          <mat-optgroup label="Fields" class="custom-select last-select">
            @for (
              question of fieldDataFiltered.multipleQuestions
                ? fieldDataFiltered.multipleQuestions[1]
                : [];
              track question.rithmId
            ) {
              <mat-option
                [value]="
                  rithmAiForm.controls.model.value === actionType.ImageGenerator
                    ? question.rithmId
                    : stationFieldPrefix.CustomField + question.rithmId
                "
              >
                <ng-container
                  [ngTemplateOutletContext]="{
                    question: question
                  }"
                  [ngTemplateOutlet]="selectOption"
                />
              </mat-option>
            }
          </mat-optgroup>
        }
      </mat-select>
    </mat-form-field>
  </section>

  <!-- Build a prompt.-->
  <section class="sub-section">
    <ng-container
      [ngTemplateOutletContext]="{
        title: 'Build a prompt.',
        subtitle: 'Build a prompt to send to RithmAI.'
      }"
      [ngTemplateOutlet]="headerSection"
    />

    <!-- Formatted Spacing.-->
    <section class="w-full flex flex-row justify-between items-center">
      <ng-container
        [ngTemplateOutletContext]="{
          title: 'Formatted Spacing',
          subtitle: 'Include spacing between each prompt item.'
        }"
        [ngTemplateOutlet]="headerSection"
      />

      <mat-slide-toggle formControlName="formattedSpacing" />
    </section>

    <!-- Prompts. -->
    <section class="sub-section">
      @for (promptId of promptIds; track promptId; let i = $index) {
        <div class="pb-4">
          <app-select-prompt-field
            [bucketQuestions]="bucketQuestions()"
            [selectedModel]="rithmAiForm.controls.model.value"
            [promptValue]="rithmAiForm.controls.prompt.value[i]"
            [canBeRemoved]="i !== 0"
            (promptValueOutput)="setPromptValue(i, $event)"
            (removePrompt)="removePromptControlInForm(i, promptId)"
          />
        </div>
      }
      <button
        mat-button
        type="button"
        class="w-[106px]"
        (click)="addPromptControlInForm()"
      >
        <div class="flex space-x-2 items-center !text-gray-800 !font-semibold">
          <i class="fal fa-plus fa-lg"></i>
          <span class="text-sm">VALUE</span>
        </div>
      </button>
    </section>
  </section>
</form>

<!-- Template for the header of each section.-->
<ng-template #headerSection let-title="title" let-subtitle="subtitle">
  <div class="w-full py-4">
    <p class="sub-section-title">{{ title }}</p>
    <p class="sub-section-description">{{ subtitle }}</p>
  </div>
</ng-template>

<!-- The select option template. -->
<ng-template #selectOption let-question="question">
  <div class="flex gap-4 items-center">
    <span class="w-5 text-center">
      <i
        class="text-gray-400 text-lg"
        [ngClass]="componentHelper.componentIcon(question.questionType)"
      ></i>
    </span>
    <p class="text-base font-medium text-primary-800">
      {{ question.prompt }}
    </p>
  </div>
</ng-template>

<!-- Template for the image settings section.-->
<ng-template #imageSettingsSection>
  <form [formGroup]="imageSettingsForm" class="sub-section">
    <ng-container
      [ngTemplateOutletContext]="{
        title: 'Image Settings',
        subtitle: 'Control what kind of image is returned'
      }"
      [ngTemplateOutlet]="headerSection"
    />

    <div class="flex flex-col gap-y-3">
      <mat-form-field
        appearance="outline"
        class="w-full"
        subscriptSizing="dynamic"
      >
        <mat-label>Quality</mat-label>
        <mat-select formControlName="quality">
          <mat-select-trigger>
            {{
              getSelectLabel(
                imageSettingsForm.controls.quality.value,
                rithmAiActionHelper.qualityOptions
              )
            }}
          </mat-select-trigger>
          @for (option of rithmAiActionHelper.qualityOptions; track $index) {
            <mat-option [value]="option.value" class="select-option !h-[72px]">
              <p class="option-title">
                {{ option.title }}
              </p>
              <p class="option-description">
                {{ option.description }}
              </p>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full"
        subscriptSizing="dynamic"
      >
        <mat-label>Style</mat-label>
        <mat-select formControlName="style">
          <mat-select-trigger>
            {{
              getSelectLabel(
                imageSettingsForm.controls.style.value,
                rithmAiActionHelper.styleOptions
              )
            }}
          </mat-select-trigger>
          @for (option of rithmAiActionHelper.styleOptions; track $index) {
            <mat-option [value]="option.value" class="select-option !h-[89px]">
              <p class="option-title">
                {{ option.title }}
              </p>
              <p class="option-description">
                {{ option.description }}
              </p>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="w-full"
        subscriptSizing="dynamic"
      >
        <mat-label>Size</mat-label>
        <mat-select formControlName="size">
          @for (option of rithmAiActionHelper.sizeOptions; track $index) {
            <div
              [matTooltipDisabled]="
                option?.isEnabled(imageSettingsForm.controls.quality.value) ||
                false
              "
              [matTooltip]="option.disabledTooltip ?? ''"
              matTooltipPosition="above"
            >
              <mat-option
                [value]="option.value"
                class="select-option"
                [disabled]="
                  !option?.isEnabled(
                    imageSettingsForm.controls.quality.value
                  ) || false
                "
              >
                <p class="option-title">
                  {{ option.title }}
                </p>
              </mat-option>
            </div>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  @if (imageSettingsForm.controls.quality.value === imageQualityType.Standard) {
    <section class="bg-sky-50 rounded-lg p-4">
      <span class="text-primary-800 font-medium">Warning:</span>
      <p class="text-secondary-600">
        Standard image prompts must not exceed 900 characters in order to
        produce a result.
      </p>
    </section>
  }
</ng-template>
