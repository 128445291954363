<section class="inline-flex items-center justify-start w-full pb-4">
  <div
    cdkDropList
    [cdkDropListData]="actions"
    (cdkDropListDropped)="dropAction($event)"
    class="w-full"
  >
    @for (action of actions; track action.rithmId) {
      <section
        cdkDrag
        class="flex cdk-custom-box"
        [id]="action.rithmId"
        [attr.data-testid]="action.rithmId"
      >
        <div class="w-10 flex flex-col justify-center items-center">
          @if (isLoadingOrder && actionRithmIdOrdered === action.rithmId) {
            <app-loading-indicator [inlineText]="' '" class="ml-3" />
          } @else if (isLoadingOrder) {
            <i
              class="fa-light fa-grip-dots-vertical drag-action opacity-25"
            ></i>
          } @else {
            <i
              cdkDragHandle
              class="fa-light fa-grip-dots-vertical cursor-pointer hover:cursor-move drag-action"
            ></i>
          }
        </div>
        @if (action.type === actionTypesEnum.FlowContainer) {
          <!-- Actions to section flow.  -->
          <app-flow-container-action
            class="w-full"
            [action]="action"
            [ruleRithmId]="ruleRithmId"
            [stations]="stations"
            [orderOfOperations]="orderOfOperations"
            [totalActions]="actions.length"
          />
        }

        @if (actionHelper.containerActions.includes(action.type)) {
          <!-- Actions to section containers.  -->
          <app-container-actions
            class="w-full"
            [stationRithmId]="stationRithmId"
            [actions]="[action]"
            [totalActions]="actions.length"
            [refreshIdContainer]="refreshIdContainer"
            [inputFrameWidgetItems]="inputFrameWidgetItems"
            [bucketQuestions]="bucketQuestions"
            [stationLibraryRelationships]="stationLibraryRelationships"
            [stationLibrariesLoading]="stationLibrariesLoading"
            [orderOfOperations]="orderOfOperations"
            [showRelationshipActions]="showRelationshipActions"
            [showAppendFieldActions]="showAppendFieldActions"
            [showActionMoveContainer]="showActionMoveContainer"
            [showAdvancedUpdateAction]="showAdvancedUpdateAction"
            [showParentChildRelationship]="showParentChildRelationship"
            [showNumberFieldRules]="showNumberFieldRules()"
            [showFlowedBy]="showFlowedBy"
            [ruleRithmId]="ruleRithmId"
          />
        }
        @if (userActions.includes(action.type)) {
          <!-- Actions to section users.  -->
          <app-user-actions
            class="w-full"
            [orderOfOperations]="orderOfOperations"
            [bucketQuestions]="bucketQuestions"
            [stationRithmId]="stationRithmId"
            [actions]="[action]"
            [ruleRithmId]="ruleRithmId"
            [totalActions]="actions.length"
          />
        }
        <!-- Actions to section notifications.  -->
        @if (actionHelper.notificationActions.includes(action.type)) {
          <app-notifications-action
            class="w-full"
            [stationRithmId]="stationRithmId"
            [actions]="[action]"
            [refreshIdContainer]="refreshIdContainer"
            [orderOfOperations]="orderOfOperations"
            [ruleRithmId]="ruleRithmId"
          />
        }
        <!-- Actions to section integrations.  -->
        @if (actionHelper.integrationActions.includes(action.type)) {
          <app-integrations
            class="w-full"
            [actions]="[action]"
            [bucketQuestions]="bucketQuestions"
            [ruleRithmId]="ruleRithmId"
            [stationRithmId]="stationRithmId"
            [refreshIdContainer]="refreshIdContainer"
            [showOptionSqlIntegration]="showOptionSqlIntegration"
            [showList]="true"
            [orderOfOperations]="orderOfOperations"
          />
        }

        <!-- RithmAI actions -->
        @if (rithmAiActions.includes(action.type)) {
          <app-rithm-ai-action
            class="w-full"
            [orderOfOperations]="orderOfOperations"
            [bucketQuestions]="bucketQuestions"
            [action]="action"
            [totalActions]="actions.length"
            [savingAction]="savingAction()"
            (cancelAction)="cancelAction.emit()"
            (saveAction)="saveAction.emit($event)"
            (eventAction)="eventAction.emit($event)"
          />
        }
        <div class="cdk-custom-placeholder" *cdkDragPlaceholder></div>
      </section>
    }
  </div>
</section>
