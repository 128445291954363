import { Injectable } from '@angular/core';

/**
 * Navigation issue helper.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationElementHelper {
  /**
   * Go to element specific.
   * @param elementId Element id to find.
   * @param parentId Parent id.
   */
  goToElement(elementId: string, parentId: string): void {
    const element =
      document.getElementById(elementId) ?? document.getElementById(parentId);
    element && element.scrollIntoView({ behavior: 'smooth' });
  }
}
