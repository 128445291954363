import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  effect,
  input,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConditionHelper, TermsGeneric } from 'src/helpers';
import {
  FieldDataType,
  FieldsGroup,
  PowerTrigger,
  Question,
  QuestionFieldType,
  TypedFormControls,
} from 'src/models';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';
import { CommonModule } from '@angular/common';

type FieldUpdateTriggerForm = TypedFormControls<Record<string, string>>;

/**
 * Field Update Trigger Component.
 */
@Component({
  selector: 'app-field-update-trigger',
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatMenuModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSearchComponent,
  ],
  templateUrl: './field-update-trigger.component.html',
  styleUrl: './field-update-trigger.component.scss',
})
export class FieldUpdateTriggerComponent implements OnInit {
  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** List of fields not suitable to be updated using the trigger. */
  readonly areNotFieldUpdateTrigger = [
    QuestionFieldType.CustomId,
    QuestionFieldType.Button,
  ];

  /** The field update triggers. */
  fieldUpdateTriggers = input.required<PowerTrigger[]>();

  /** The questions to relate the trigger. */
  @Input({ required: true }) bucketQuestions: Question[] = [];

  /** Remove field update trigger. */
  @Output() updateFieldTrigger = new EventEmitter<{
    /** Flag when there is a change in toggle. */
    toggle: boolean;
    /** Toggle value. */
    isDisabled: boolean;
    /** Trigger selected. */
    triggerSelected: string;
    /** Indicates if the change is valid. */
    validChange: boolean;
  }>();

  /** Update value in field update trigger. */
  @Output() updateDataInFieldTrigger = new EventEmitter<{
    /** Question selected. */
    questionSelected: string;
    /** Trigger selected. */
    triggerSelected: string;
    /** Indicates if the change is valid. */
    validChange: boolean;
  }>();

  /** Form to question selected in trigger. */
  questionsSelected = new FormGroup<FieldUpdateTriggerForm>({});

  /** Field updated list. */
  fieldUpdatedList: PowerTrigger[] = [];

  /** Filtered questions. */
  filteredQuestions: FieldsGroup = {
    /** Fields. */
    fields: [],
    /** Container info questions. */
    containerInfo: [],
    /** Station info questions. */
    stationInfo: [],
  };

  /** Control object filter for select. */
  filterFieldDataType!: FieldDataType;

  /** Indicates if any field is being updated. */
  updatingField = false;

  constructor() {
    effect(() => {
      this.createFormGroup();
    });
  }

  /** Init lifecycle. */
  ngOnInit(): void {
    this.filteredQuestions.fields = ConditionHelper.getStationQuestions(
      this.bucketQuestions,
    ).filter(
      ({ questionType }) =>
        !this.areNotFieldUpdateTrigger.includes(questionType),
    );

    this.filteredQuestions.containerInfo = [
      this.createQuestionFields(QuestionFieldType.ContainerName),
      this.createQuestionFields(QuestionFieldType.AssignedUser),
    ];
    this.filteredQuestions.stationInfo = [
      this.createQuestionFields(QuestionFieldType.StationName),
    ];
  }

  /** Create Form Group to select with question. */
  createFormGroup(): void {
    this.fieldUpdateTriggers().forEach(({ rithmId, source }) => {
      const formControl = new FormControl<string>(source);
      formControl.setValidators(Validators.required);
      if (source && !source.length) {
        formControl.markAsTouched();
      }
      this.questionsSelected.addControl(
        'question-' + rithmId,
        formControl as FormControl<string>,
      );
    });
    this.updatingField = false;
    this.fieldUpdatedList = this.fieldUpdateTriggers();
  }

  /**
   * Add question selected to current field update trigger.
   * @param questionSelected Question selected.
   * @param triggerSelected Trigger Selected.
   */
  addQuestionToTrigger(
    questionSelected: string,
    triggerSelected: string,
  ): void {
    this.updateDataInFieldTrigger.emit({
      questionSelected,
      triggerSelected,
      validChange: this.questionsSelected.valid,
    });
  }

  /**
   * Add filters to station info section.
   * @param questionField Question field type to create.
   * @returns A new question.
   */
  createQuestionFields(questionField: QuestionFieldType): Question {
    return {
      prompt:
        questionField === QuestionFieldType.AssignedUser
          ? 'Assigned to'
          : _.startCase(questionField),
      rithmId: uuidv4(),
      enabled: true,
      questionType: questionField,
      isReadOnly: false,
      isRequired: false,
      isPrivate: false,
      children: [],
    };
  }

  /**
   * Handle field update trigger.
   * @param triggerSelected Trigger Selected.
   * @param isToggle When change comes from toggle.
   */
  handleFieldUpdateTrigger(
    triggerSelected: PowerTrigger,
    isToggle = false,
  ): void {
    if (!isToggle) {
      this.updatingField = true;
      this.questionsSelected.removeControl(
        'question-' + triggerSelected.rithmId,
      );
    }
    this.updateFieldTrigger.emit({
      toggle: isToggle,
      isDisabled: triggerSelected.isDisabled,
      triggerSelected: triggerSelected.rithmId,
      validChange: this.questionsSelected.valid,
    });
  }

  /**
   * Disable question when is previous selected.
   * @param questionSelected Question selected.
   * @param rithmId Trigger rithmId selected.
   * @returns A boolean.
   */
  isDisabled(questionSelected: string, rithmId: string): boolean {
    const selectedQuestions = this.questionsSelected.value;
    const formValues = Object.values(selectedQuestions);
    const formKeys = Object.keys(selectedQuestions);
    return formValues.some(
      (question, i) => question === questionSelected && formKeys[i] !== rithmId,
    );
  }
}
