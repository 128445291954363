import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import {
  MatAutocomplete,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import {
  ActionType,
  AdvancedUpdateFieldData,
  CustomField,
  FieldDataType,
  PowerAction,
  Question,
  QuestionFieldIcon,
  QuestionFieldType,
  Station,
  StationContainerLabel,
} from 'src/models';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import {
  ActionHelper,
  ComponentHelper,
  StationTerms,
  TermsGeneric,
} from 'src/helpers';
import { Subject, first, forkJoin, takeUntil } from 'rxjs';
import { PopupService } from 'src/app/core/popup.service';
import { StationService } from 'src/app/core/station.service';
import { MatIconModule } from '@angular/material/icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { MatDividerModule } from '@angular/material/divider';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';
/**
 * Component for Advanced Update field data Action.
 */
@Component({
  selector: 'app-advanced-update-action-form',
  templateUrl: './advanced-update-action-form.component.html',
  styleUrls: ['./advanced-update-action-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatChipsModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxSearchComponent,
    MatTooltipModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatDividerModule,
    LoadingIndicatorComponent,
  ],
})
export class AdvancedUpdateActionFormComponent implements OnDestroy {
  /* Component Helper. */
  componentHelper = ComponentHelper;

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Autocomplete. */
  @ViewChild('autoSourceField') autocompleteField!: MatAutocomplete;

  /** Action to update. */
  advancedUpdateFieldDataToUpdate!: AdvancedUpdateFieldData;

  /** List of stations. */
  @Input({ required: true }) stations: Station[] = [];

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether you are saving the current action . */
  @Input() savingAction = false;

  /** Confirms whether the action is being edited. */
  @Input() editingAction = false;

  /** Whether you are deleting the current action in parent. */
  @Input() deletingAction = false;

  /** The station rithmId. */
  @Input({ required: true }) stationRithmId = '';

  /** The current station. */
  @Input({ required: true }) currentStation: Station | undefined = undefined;

  /** If editing  advance update section.*/
  @Input({ required: true }) isEditAdvanceUpdateAction = false;

  /** Action to update. */
  private _action!: PowerAction;

  /** Contains the action of the power that will be edited. */
  @Input() set action(actionToUpdate: PowerAction | null) {
    this.form = this.fb.group({
      stations: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
      ]),
      stationsFilterCtrl: new FormControl([]),
      stationsFiltered: new FormControl([]),
      field: new FormControl('', [Validators.required]),
      fieldFilterCtrl: new FormControl('', [Validators.required]),
      source: new FormControl('', [Validators.required]),
      sourceFilterCtrl: new FormControl(''),
      sourceFiltered: new FormControl([], [Validators.required]),
      stationBucket: new FormControl({ value: '', disabled: true }),
      stationBucketCtrl: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      matching: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      matchingFilterCtrl: new FormControl(''),
      matchingFiltered: new FormControl({ value: [], disabled: true }),
    });
    if (actionToUpdate === null) {
      this._action = {
        order: 1,
        rithmId: uuidv4(),
        type: ActionType.AdvancedUpdateField,
        target: '',
        data: JSON.stringify(this.advancedUpdateFieldData),
        resultMapping: '',
        header: '',
      };
      this.setEmptyForm();
    } else {
      this._action = actionToUpdate;
      this.advancedUpdateFieldData = JSON.parse(
        this._action.data,
      ) as AdvancedUpdateFieldData;
      this.advancedUpdateFieldDataToUpdate = {
        ...this.advancedUpdateFieldData,
      };
      this.getAllDataEdit();
    }
  }

  /** Feature flag to show the rules for Number field in conditions. */
  @Input() showNumberFieldRules = false;

  /**
   * Return action to update.
   * @returns Action.
   */
  get action(): PowerAction {
    return this._action;
  }

  /** Emit Cancel the new action to add. */
  @Output() cancelForm = new EventEmitter<boolean>();

  /** Emit the new action to add it into the list of actions in the power. */
  @Output() actionEmitter = new EventEmitter<PowerAction>();

  /** Emit the current action to delete it. */
  @Output() actionToRemove = new EventEmitter<PowerAction>();

  /** Question-types to filter out. */
  readonly questionTypesToFilter = [
    QuestionFieldType.Button,
    QuestionFieldType.Function,
    QuestionFieldType.ParentContainerLink,
    QuestionFieldType.ParentStationLink,
  ];

  /* List of matchingQuestions. */
  matchingQuestions: Question[] = [];

  /* List of matchingQuestions. */
  matchingQuestionsEditUpdating: Question[] = [];

  /* List of matchingQuestions. */
  matchingQuestionsEditMatching: Question[] = [];

  /* List of matchingQuestions filtered. */
  matchingQuestionsFiltered!: FieldDataType;

  /* List of stationBuckets of UPDATES TO (SOURCE):. */
  stationBuckets: Question[] = [];

  /* List filtered of stationBuckets of UPDATES TO (SOURCE):. */
  stationBucketsFiltered: Question[] = [];

  /** Control object filter for select. */
  fieldFilterCtrlFiltered!: FieldDataType;

  /** Control object filter for select. */
  containerInfoFilteredDataFieldType!: FieldDataType;

  /* List of stationContainerLabels. */
  stationContainerLabelList: StationContainerLabel[] = [];

  /** Form account info.  */
  form!: FormGroup;

  /** Initial control name. */
  controlName: 'stations' | 'source' | 'matching' = 'stations';

  /** The Container Info. */
  containerInfo: CustomField[] = [
    {
      prompt: this.termsGeneric.Container.Single + ' Name',
      enabled: true,
      icon: 'fa-thin fa-box',
      questionType: QuestionFieldType.ContainerName,
    },
    {
      prompt: 'Assigned User',
      enabled: true,
      icon: 'fa-kit fa-container-user',
      questionType: QuestionFieldType.AssignedUser,
    },
  ];

  /** The Container Info filtered. */
  containerInfoFiltered: CustomField[] = [
    {
      prompt: this.termsGeneric.Container.Single + ' Name',
      enabled: true,
      icon: 'fa-thin fa-box',
      questionType: QuestionFieldType.ContainerName,
    },
    {
      prompt: 'Assigned User',
      enabled: true,
      icon: 'fa-kit fa-container-user',
      questionType: QuestionFieldType.AssignedUser,
    },
  ];

  /** The Container Info filtered. */
  containerInfoField: CustomField[] = [
    {
      prompt: this.termsGeneric.Container.Single + ' Name',
      enabled: true,
      icon: 'fa-thin fa-box',
      questionType: QuestionFieldType.ContainerName,
    },
    {
      prompt: 'Assigned User',
      enabled: true,
      icon: 'fa-kit fa-container-user',
      questionType: QuestionFieldType.AssignedUser,
    },
    {
      prompt: 'Create On',
      enabled: true,
      icon: 'fa-light fa-calendar-pen',
      questionType: QuestionFieldType.CreatedOn,
    },
    {
      prompt: 'Present Time',
      enabled: true,
      icon: 'fa-light fa-calendar-clock',
      questionType: QuestionFieldType.PresentTime,
    },
  ];

  /** The Container Info filtered. */
  containerInfoFilteredField: CustomField[] = [
    {
      prompt: this.termsGeneric.Container.Single + ' Name',
      enabled: true,
      icon: 'fa-thin fa-box',
      questionType: QuestionFieldType.ContainerName,
    },
    {
      prompt: 'Assigned User',
      enabled: true,
      icon: 'fa-kit fa-container-user',
      questionType: QuestionFieldType.AssignedUser,
    },
  ];

  /**Advance data to use. */
  advancedUpdateFieldData: AdvancedUpdateFieldData = {
    targetStations: [],
    field: '',
    sourceStations: [],
    sourceValue: '',
    matchingFields: [],
  };

  /* Question Icon. */
  questionFieldIcon = QuestionFieldIcon;

  /** If the option custom value should be disabled. */
  disableCustomValue = signal<boolean>(false);

  /** Show bucket. */
  showBucket = true;

  /** Is matching loading. */
  isEditingLoading = false;

  /** Subject that emits when the component has been destroyed. */
  protected _destroyed$ = new Subject<void>();

  /** Subject that emits when the component has been destroyed. */
  protected _destroyedEdit$ = new Subject<void>();

  /** Matching updating section. */
  matchingUpdating: Question[] | StationContainerLabel[] = [];

  /** The Container Info. */
  containerInfoSource: CustomField[] = [
    {
      prompt: this.termsGeneric.Container.Single + ' Name',
      enabled: true,
      icon: 'fa-thin fa-box',
      questionType: QuestionFieldType.ContainerName,
    },
    {
      prompt: 'Assigned User',
      enabled: true,
      icon: 'fa-kit fa-container-user',
      questionType: QuestionFieldType.AssignedUser,
    },
  ];

  constructor(
    private stationService: StationService,
    private popupService: PopupService,
    private fb: FormBuilder,
  ) {}

  /** Set empty form. */
  setEmptyForm(): void {
    if (!this.advancedUpdateFieldData.targetStations.length) {
      this.advancedUpdateFieldData.targetStations = [
        this.currentStation?.rithmId || '',
      ];
    }
    this.currentStation &&
      (this.stations = [this.currentStation, ...this.stations]);

    this._setConfigInitialForm();
    this.listenAutocomplete$();
    this.getDataSection('stations');
    this.getDataSection('source');
  }

  /** Set config the form initial. */
  private _setConfigInitialForm(): void {
    const stationLoaded: Station[] = [];
    this.advancedUpdateFieldData?.targetStations &&
      this.advancedUpdateFieldData.targetStations.forEach(
        (selectedStationRithmId) => {
          const stationToAdd = this.stations.find(
            (station) => station.rithmId === selectedStationRithmId,
          );
          stationToAdd && stationLoaded.push(stationToAdd);
        },
      );

    this.form.controls[this.controlName].setValue([
      ...(this.form.controls[this.controlName]?.value || []),
      ...stationLoaded,
    ]);

    this.form.controls[this.controlName + 'Filtered'].setValue(this.stations);
    this.form.controls['sourceFiltered'].setValue(this.stations);
  }

  /** Set config the form initial. */
  private _setConfigInitialFormEdit(): void {
    if (!this.advancedUpdateFieldData.targetStations.length) {
      this.advancedUpdateFieldData.targetStations = [
        this.currentStation?.rithmId || '',
      ];
    }
    this.currentStation &&
      (this.stations = [this.currentStation, ...this.stations]);
    const stationLoaded: Station[] = [];
    this.advancedUpdateFieldDataToUpdate?.targetStations &&
      this.advancedUpdateFieldDataToUpdate.targetStations.forEach(
        (selectedStationRithmId) => {
          const stationToAdd = this.stations.find(
            (station) => station.rithmId === selectedStationRithmId,
          );
          stationToAdd && stationLoaded.push(stationToAdd);
        },
      );

    this.form.controls['stations'].setValue([
      ...(this.form.controls['stations']?.value || []),
      ...stationLoaded,
    ]);

    this.form.controls['stationsFiltered'].setValue(this.stations);
    this.form.controls['sourceFiltered'].setValue(this.stations);
  }

  /** Listen changes in autocomplete. */
  private listenAutocomplete$(): void {
    //Updating Listener
    this.form.controls['stations'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((stations: []) => {
        this.setStateFieldInput();
        this.setStationBucket();
        this.form.controls['fieldFilterCtrl'].setValue('');
        this.form.controls['field'].setValue('');
        stations.length && this.getDataSection('stations');
        stations.length &&
          (this.form.controls['source'].value as []).length &&
          this.getDataSection('matching');
      });
    this.form.controls[this.controlName + 'FilterCtrl'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((text) => {
        this.form.controls[this.controlName + 'Filtered'].setValue(
          this.stations.filter((station) =>
            station.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
          ),
        );
      });

    //Field Listener
    this.form.controls['field'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((field) => {
        this.advancedUpdateFieldData.field = field?.rithmId
          ? 'rid:' + field.rithmId
          : field?.questionType || '';
        this.form.controls['stationBucketCtrl'].setValue('');
        this.form.controls['matching'].setValue([]);
        this.form.controls['matching'].disable();
        this.setStationBucket();
      });

    //Source Listener
    this.form.controls['source'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.advancedUpdateFieldData.sourceStations = [
          ..._.map(this.form.controls['source'].value, 'rithmId'),
        ];
        this.form.controls['stationBucketCtrl'].setValue('');
        this.form.controls['stationBucket'].setValue('');

        this.setStationBucket();
      });
    this.form.controls['sourceFilterCtrl'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((text) => {
        this.form.controls[this.controlName + 'Filtered'].setValue(
          this.stations.filter((station) =>
            station.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
          ),
        );
      });

    this.form.controls['stationBucketCtrl'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((sourceValue) => {
        this.fieldSourceFilter();
        this.advancedUpdateFieldData.sourceValue = sourceValue.rithmId
          ? 'rid:' + sourceValue.rithmId
          : sourceValue.questionType || sourceValue;
      });

    this.form.controls['matching'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((sourceValue: []) => {
        const matchingFields: string[] = [];
        !this.isEditAdvanceUpdateAction &&
          sourceValue?.length &&
          sourceValue.forEach((field: Question | CustomField | string) => {
            if (typeof field === 'string') {
              matchingFields.push('rid:' + field);
            } else {
              if (field && 'rithmId' in field) {
                matchingFields.push('rid:' + (field as Question).rithmId);
              } else {
                matchingFields.push(
                  (field as CustomField)?.questionType as string,
                );
              }
            }
          });
        !this.isEditAdvanceUpdateAction &&
          (this.advancedUpdateFieldData.matchingFields = matchingFields);
      });

    this.form.controls['matchingFilterCtrl'].valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe((text) => {
        this.form.controls['matchingFiltered'].setValue(
          this.matchingQuestionsEditMatching.filter((question) =>
            question.prompt
              .toLocaleLowerCase()
              .includes(text.toLocaleLowerCase()),
          ),
        );
      });
  }

  /**
   * Notify and show pop-up for get MatchingQuestion.
   * @param message Message for show in popup.
   * @param isError If the popup is an error message.
   */
  public notify(message: string, isError = false): void {
    this.popupService.notify(message, isError);
  }

  /**
   * Get questions by getMatchingQuestions of stationService.
   * @param section  It could be 'stations' | 'source' | 'matching'.
   */
  getDataSection(section: 'stations' | 'source' | 'matching'): void {
    let stationRithmIdsUpdatedSection = [];
    this.containerInfoFiltered = [...this.containerInfo];
    if (
      (this.form.controls[section].value as []).length ||
      (this.isEditAdvanceUpdateAction &&
        (this.form.controls['stations'].value as []).length &&
        section === 'matching') ||
      (!this.isEditAdvanceUpdateAction && section === 'matching')
    ) {
      if (section === 'matching') {
        stationRithmIdsUpdatedSection = _.map(
          this.form.controls['stations'].value,
          'rithmId',
        ).concat(_.map(this.form.controls['source'].value, 'rithmId'));
      } else {
        stationRithmIdsUpdatedSection = _.map(
          this.form.controls[section].value,
          'rithmId',
        );
        section === 'stations' &&
          this.form.controls['fieldFilterCtrl'].disable();
      }
      const requestArray = [
        this.stationService.getMatchingQuestions(
          stationRithmIdsUpdatedSection,
          section === 'matching',
        ),
        this.stationService.getStationContainerLabels(
          stationRithmIdsUpdatedSection,
        ),
      ];
      forkJoin(requestArray)
        .pipe(first())
        .subscribe({
          next: ([matchingQuestions, labelData]) => {
            let containerLabel = '';
            const labelDataArray: [] = labelData as [];
            const matchingQuestionsClean = (
              matchingQuestions as Question[]
            ).filter(
              (question) =>
                !StationTerms.ContainerInfoItemsAsQuestion.find(
                  (f) => f.questionType === question.questionType,
                ),
            );
            if (
              labelDataArray.some(
                (data: StationContainerLabel) => data.containerLabel,
              )
            ) {
              containerLabel = _.join(
                _.compact(_.map(labelDataArray, 'containerLabel')),
                ' / ',
              );
            } else {
              containerLabel = this.termsGeneric.Container.Single + ' Name';
            }

            if (section === 'stations') {
              this.matchingQuestions = _.uniqBy(
                (matchingQuestionsClean as Question[]).filter(
                  ({ questionType }) =>
                    !this.questionTypesToFilter.includes(questionType),
                ),
                'rithmId',
              );
              this.matchingQuestionsEditUpdating = [...this.matchingQuestions];

              containerLabel &&
                this.containerInfoFiltered[0] &&
                (this.containerInfoFiltered[0].prompt = containerLabel);
              this.form.controls['fieldFilterCtrl'].enable();
            } else if (section === 'source') {
              const fieldType: QuestionFieldType = (
                this.form.controls['field'].value as Question
              ).questionType;

              if (
                containerLabel &&
                this.containerInfoFilteredField[0] &&
                this.containerInfoFilteredField[0].questionType ===
                  QuestionFieldType.ContainerName
              ) {
                this.containerInfoFilteredField[0].prompt = containerLabel;
              }
              this.containerInfoFilteredField = this.containerInfoField.filter(
                (c) =>
                  c.questionType &&
                  ActionHelper.allowedTypesToUpdate(
                    fieldType,
                    c.questionType as QuestionFieldType,
                  ),
              );

              this.stationBuckets = (matchingQuestions as Question[])
                .filter(
                  (q) =>
                    !StationTerms.ContainerInfoItemsAsQuestion.some(
                      (x) => x.questionType === q.questionType,
                    ),
                )
                .filter((st) =>
                  ActionHelper.allowedTypesToUpdate(fieldType, st.questionType),
                );

              /** Should append the Number fields along with Currency/Number fields as per the Number Field Logic criteria. */
              if (
                this.showNumberFieldRules &&
                [QuestionFieldType.Currency, QuestionFieldType.Number].includes(
                  fieldType,
                )
              ) {
                const questionList = this.getQuestionList(
                  matchingQuestions as Question[],
                  fieldType === QuestionFieldType.Currency
                    ? QuestionFieldType.Number
                    : QuestionFieldType.Currency,
                );
                this.stationBuckets = this.stationBuckets.concat(questionList);
                this.stationBuckets = _.uniqBy(this.stationBuckets, 'rithmId');
              }

              this.stationBucketsFiltered = this.stationBuckets;
            } else if (section === 'matching') {
              this.form.controls[section + 'Filtered'].setValue(
                (matchingQuestions as Question[]).filter(
                  (st) =>
                    st.questionType !== QuestionFieldType.ContainerName &&
                    st.questionType !== QuestionFieldType.AssignedUser,
                ),
              );
              this.matchingQuestionsEditMatching =
                this.form.controls[section + 'Filtered'].value;

              this.form.controls['stationBucketCtrl'].value &&
                this.form.controls['matching'].enable();
            }
          },
          error: () => {
            section === 'stations' &&
              this.form.controls['fieldFilterCtrl'].disable();
            this.notify('The fields could not be obtained', true);
          },
        });
    }
  }

  /**
   * Get the list of question fields.
   * @param matchingQuestions List of matching question.
   * @param questionType Question required to filter.
   * @returns Returns a filtered list of questions from the passed list.
   */
  private getQuestionList(
    matchingQuestions: Question[],
    questionType: QuestionFieldType,
  ): Question[] {
    return (matchingQuestions as Question[]).filter((st) =>
      ActionHelper.allowedTypesToUpdate(questionType, st.questionType),
    );
  }

  /**
   * Cancel the process of Update field data Action.
   */
  cancelAction(): void {
    this.cancelForm.emit();
  }

  /**
   * Remove option Selected.
   * @param controlName Control NAme.
   * @param index Index.
   */
  removeSelectedOption(
    controlName: 'stations' | 'source',
    index: number,
  ): void {
    const newData: [] = [...(this.form.controls[controlName].value as [])];
    newData.splice(index, 1);
    this.form.controls[controlName].setValue(newData);
    if (controlName === 'stations') {
      this.form.controls['fieldFilterCtrl'].setValue([]);
      this.form.controls['matching'].setValue([]);
      this.form.controls['matching'].disable();
      this.form.controls['field'].setValue('');
    }

    if (controlName === 'source') {
      this.form.controls['stationBucket'].setValue('');
    }

    this.setStateFieldInput();
  }

  /**
   * Set control name.
   * @param controlName Control Name.
   */
  setControlName(controlName: 'stations' | 'source'): void {
    this.controlName = controlName;
  }

  /**
   * Set Show bucket.
   */
  setShowBucket(): void {
    this.showBucket = !this.showBucket;
    this.form.controls['stationBucketCtrl'].setValue('');
  }

  /**
   * Set control name.
   * @param stationRithmId Station rithmId.
   * @returns If it's exists in update section.
   */
  isInUpdatingSection(stationRithmId: string): boolean {
    const station = (this.form.controls['stations'].value as []).find(
      (st: Station) => st.rithmId === stationRithmId,
    );
    if (station && (this.form.controls['source'].value as [])) {
      const index = (this.form.controls['source'].value as []).findIndex(
        (st: Station) => st.rithmId === stationRithmId,
      );

      if (index > -1) {
        const newData: [] = [...(this.form.controls['source'].value as [])];
        newData.splice(index, 1);
        this.form.controls['source'].setValue(newData);
      }
    }

    return !!station;
  }

  /**
   * Set State Field Input.
   */
  setStateFieldInput(): void {
    if ((this.form.controls['stations'].value as []).length) {
      this.form.controls['fieldFilterCtrl'].enable();
    } else {
      this.containerInfoFiltered = [];
      this.form.controls['fieldFilterCtrl'].disable();
      this.form.controls['fieldFilterCtrl'].setValue([]);
      this.form.controls['field'].setValue([]);
      this.form.controls['matching'].setValue([]);
      this.form.controls['matching'].disable();
    }
  }

  /**
   * Set Field value.
   * @param option Option to set.
   */
  setField(option: CustomField | Question): void {
    this.disableCustomValue.set(option.questionType === QuestionFieldType.File);
    // if the option custom value is showing should change to select.
    if (this.disableCustomValue() && !this.showBucket) {
      this.showBucket = true;
    }
    this.form.controls['field'].setValue(option);
  }

  /**
   * Display the proper value in the autocomplete field since we are working with JSON values.
   * @param field The field information to be displayed.
   * @returns The prompt for the current field.
   */
  showPrompt(field: Question | CustomField): string {
    return field ? field.prompt : field;
  }

  /**
   * Field Filter.
   */
  public fieldFilter(): void {
    const searchValue = (
      this.form.controls['fieldFilterCtrl'].value as string
    ).toLocaleLowerCase();
    this.containerInfoFiltered = [...this.containerInfo].filter((info) =>
      info.prompt.toLocaleLowerCase().includes(searchValue),
    );
  }

  /**
   * Field Filter.
   */
  public fieldSourceFilter(): void {
    const value = this.form.controls['stationBucketCtrl'].value;
    if (!this.showBucket && typeof value === 'string' && value) {
      this.form.controls['stationBucket'].setValue(value);
      this.form.controls['matching'].enable();
    } else if (
      this.showBucket &&
      typeof value === 'object' &&
      'prompt' in value
    ) {
      this.form.controls['matching'].enable();
    } else {
      this.form.controls['matching'].setValue([]);
      this.form.controls['matching'].disable();
    }
  }

  /**
   * Set station bucket UPDATES TO (SOURCE) section.
   */
  setStationBucket(): void {
    if (
      this.form.controls['field'].valid &&
      this.form.controls['source'].valid
    ) {
      this.form.controls['stationBucketCtrl'].enable();
      this.getDataSection('source');
      this.form.controls['stations'] && this.getDataSection('matching');
      this.form.controls['matching'].setValue([]);
      this.form.controls['matching'].disable();
    } else {
      this.form.controls['stationBucketCtrl'].setValue('');
      this.form.controls['stationBucket'].setValue('');
      this.form.controls['stationBucketCtrl'].disable();
    }
  }

  /**
   * Saving action type changes.
   *
   */
  public addActionToPower(): void {
    this.advancedUpdateFieldData.targetStations = _.map(
      this.form.controls['stations'].value,
      'rithmId',
    );
    this.advancedUpdateFieldData.matchingFields = [];
    (this.form.controls['matching'].value as []).forEach((e) => {
      if ((e as Question)?.rithmId) {
        this.advancedUpdateFieldData.matchingFields.push(
          'rid:' + (e as Question).rithmId,
        );
      } else {
        this.advancedUpdateFieldData.matchingFields.push(
          (e as CustomField).questionType || '',
        );
      }
    });
    this.action.data = JSON.stringify(this.advancedUpdateFieldData);
    this.actionEmitter.emit(this.action);
  }

  /**
   * Set updating section.
   */
  setUpdatingSection(): void {
    if (this.advancedUpdateFieldDataToUpdate?.field) {
      const rithmId = this.advancedUpdateFieldDataToUpdate.field.replace(
        'rid:',
        '',
      );
      this.form.controls['field'].setValue(
        this.matchingQuestions.find((q) => q.rithmId === rithmId) ||
          this.containerInfo.find((item) => item.questionType === rithmId),
      );

      this.form.controls['fieldFilterCtrl'].setValue(
        this.form.controls['field'].value,
      );
    }

    this.disableCustomValue.set(
      this.form.controls.fieldFilterCtrl.value?.questionType ===
        QuestionFieldType.File,
    );
  }

  /**
   * Set station source.
   */
  setStationSource(): void {
    const stationsToLoad: Station[] = [];
    this.advancedUpdateFieldDataToUpdate.sourceStations.forEach((id) => {
      const stationToAdd = this.stations.find(
        (station) => station.rithmId === id,
      );
      stationToAdd && stationsToLoad.push(stationToAdd);
    });
    this.form.controls['source'].setValue(stationsToLoad);
  }

  /**
   * Set source section.
   */
  setSourceSection(): void {
    const rithmId = this.advancedUpdateFieldDataToUpdate.sourceValue.replace(
      'rid:',
      '',
    );
    const question: Question | undefined | CustomField =
      this.stationBuckets.find((q) => q.rithmId === rithmId) ||
      this.containerInfoField.find((q) => q.questionType === rithmId);
    const isQuestion = question && 'rithmId' in question;
    const isCustom = question && !isQuestion && 'questionType' in question;

    if (isQuestion || isCustom) {
      isCustom
        ? this.containerInfoFilteredField.push(question)
        : this.stationBucketsFiltered.push(question as Question) &&
          (this.stationBucketsFiltered = _.uniqBy(
            this.stationBucketsFiltered,
            'rithmId',
          ));

      this.showBucket = true;
      this.form.controls['stationBucketCtrl'].setValue(question || rithmId);
    } else {
      this.showBucket = false;
      this.form.controls['stationBucketCtrl'].setValue(rithmId);
    }
    this.containerInfoFilteredField = _.uniqBy(
      this.containerInfoFilteredField,
      'questionType',
    );
  }

  /**
   * Cleanup method.
   */
  setMatchingSection(): void {
    const matching: (CustomField | Question | string)[] = [];
    this.advancedUpdateFieldDataToUpdate.matchingFields.forEach((field) => {
      const rithmId = field?.replace('rid:', '');
      matching.push(
        (this.matchingQuestionsEditMatching as Question[]).find(
          (q) => q.rithmId === rithmId,
        ) ||
          this.containerInfo.find((item) => item.questionType === rithmId) ||
          rithmId,
      );

      matching.length && this.form.controls['matching'].setValue(matching);
      matching.length &&
        (this.advancedUpdateFieldDataToUpdate.matchingFields = _.map(
          matching,
          'rithmId',
        ));
      this.form.controls['matchingFiltered'].setValue(
        this.matchingQuestionsEditMatching,
      );
    });
  }

  /**
   * Get Data Edit.
   */
  getAllDataEdit(): void {
    this.isEditingLoading = true;
    const request = [
      //Stations
      this.stationService.getMatchingQuestions(
        this.advancedUpdateFieldDataToUpdate.targetStations,
      ),
      //Matching
      this.stationService.getMatchingQuestions(
        this.advancedUpdateFieldDataToUpdate.targetStations.concat(
          this.advancedUpdateFieldDataToUpdate.sourceStations,
        ),
        true,
      ),
      //Source
      this.stationService.getMatchingQuestions(
        this.advancedUpdateFieldDataToUpdate.sourceStations,
      ),
      //Container Label Stations
      this.stationService.getStationContainerLabels(
        this.advancedUpdateFieldDataToUpdate.targetStations,
      ),
      //Container Label source
      this.stationService.getStationContainerLabels(
        this.advancedUpdateFieldDataToUpdate.sourceStations,
      ),
    ];

    forkJoin(request)
      .pipe(takeUntil(this._destroyedEdit$))
      .subscribe({
        next: ([
          stations,
          matching,
          source,
          containerLabelsStation,
          containerLabelsOther,
        ]) => {
          //With out container Info.
          const matchingFiltered = (matching as Question[]).filter(
            (q: Question) =>
              !StationTerms.ContainerInfoItemsAsQuestion.find(
                (f) => f.questionType === q.questionType,
              ),
          );

          const stationsFiltered = (stations as Question[]).filter(
            (q: Question) =>
              !StationTerms.ContainerInfoItemsAsQuestion.find(
                (f) => f.questionType === q.questionType,
              ) && q.questionType !== QuestionFieldType.Function,
          );

          const sourceFiltered = (source as Question[]).filter(
            (q: Question) =>
              !StationTerms.ContainerInfoItemsAsQuestion.find(
                (f) => f.questionType === q.questionType,
              ),
          );
          this.setStationBlock(
            stationsFiltered,
            containerLabelsStation as StationContainerLabel[],
          );
          this.setMatchingSectionBlock(
            matchingFiltered,
            containerLabelsStation as StationContainerLabel[],
          );
          this.setSourceSectionBlock(
            sourceFiltered,
            containerLabelsOther as StationContainerLabel[],
          );
          this.form.controls['stationBucketCtrl'].enable();
          this.form.controls['matching'].enable();
          this.listenAutocomplete$();
          this.isEditingLoading = false;
        },
        error: () => {
          this.isEditingLoading = false;
          this.notify('The action could not be obtained', true);
          this.cancelAction();
        },
      });
  }

  /**
   * Set Source Section.
   * @param matchingQuestions Matching result.
   * @param stationLabelArray Container label array.
   */
  private setSourceSectionBlock(
    matchingQuestions: Question[],
    stationLabelArray: StationContainerLabel[],
  ) {
    const containerLabelSource = this.getContainerLabels(stationLabelArray);
    const fieldType: QuestionFieldType = (
      this.form.controls['field'].value as Question
    ).questionType;
    this.containerInfoFilteredField.map(
      (q) =>
        q.questionType === QuestionFieldType.AssignedUser &&
        (q.prompt = 'Assigned User'),
    );
    if (
      containerLabelSource &&
      this.containerInfoFilteredField[0] &&
      this.containerInfoFilteredField[0].questionType ===
        QuestionFieldType.ContainerName
    ) {
      this.containerInfoFilteredField[0].prompt = containerLabelSource;
    }
    this.containerInfoFilteredField = this.containerInfoField.filter(
      (c) => c.questionType === fieldType,
    );

    this.stationBuckets = (matchingQuestions as Question[])
      // We filter in containerInfo if there are associated questions.
      .filter(
        (q) =>
          !StationTerms.ContainerInfoItemsAsQuestion.some(
            (x) => x.questionType === q.questionType,
          ),
      )
      // Filter by the types allowed in the current questions.
      .filter((st) =>
        ActionHelper.allowedTypesToUpdate(fieldType, st.questionType),
      );

    /** Should append the Number fields along with Currency/Number fields as per the Number Field Logic criteria. */
    if (
      this.showNumberFieldRules &&
      [QuestionFieldType.Currency, QuestionFieldType.Number].includes(fieldType)
    ) {
      this.stationBuckets = this.stationBuckets.concat(
        this.getQuestionList(
          matchingQuestions as Question[],
          fieldType === QuestionFieldType.Currency
            ? QuestionFieldType.Number
            : QuestionFieldType.Currency,
        ),
      );
    }

    this.stationBucketsFiltered = this.stationBuckets;
    this.setStationSource();
    this.setSourceSection();
  }

  /**
   * Set Matching Section.
   * @param matchingQuestions Matching result.
   * @param stationLabelArray Container label array.
   */
  private setMatchingSectionBlock(
    matchingQuestions: Question[],
    stationLabelArray: StationContainerLabel[],
  ) {
    const containerLabelMatching = this.getContainerLabels(stationLabelArray);
    if (containerLabelMatching && this.containerInfoFiltered[0]) {
      this.containerInfoFiltered[0].prompt = containerLabelMatching;
    }

    this.form.controls['matchingFiltered'].setValue(
      matchingQuestions.filter(
        (st) =>
          st.questionType !== QuestionFieldType.ContainerName &&
          st.questionType !== QuestionFieldType.AssignedUser,
      ),
    );
    this.matchingQuestionsEditMatching =
      this.form.controls['matchingFiltered'].value;

    this.form.controls['stationBucketCtrl'].value &&
      this.form.controls['matching'].enable();
    this.setMatchingSection();
  }

  /**
   * Set station block.
   * @param matchingQuestions Station result.
   * @param stationLabelArray Container Labels.
   * @returns Data.
   */
  private setStationBlock(
    matchingQuestions: Question[],
    stationLabelArray: StationContainerLabel[],
  ): Question[] | StationContainerLabel[] {
    this.matchingQuestions = _.uniqBy(
      (matchingQuestions as Question[]).filter(
        ({ questionType }) =>
          // Do not show these types.
          ![QuestionFieldType.Button, QuestionFieldType.Function].includes(
            questionType,
          ),
      ),
      'rithmId',
    );
    this.matchingUpdating = [...this.matchingQuestions];
    if (
      stationLabelArray.some(
        (data: StationContainerLabel) => data.containerLabel,
      )
    ) {
      this.containerInfo[0].prompt = _.join(
        _.compact(_.map(stationLabelArray, 'containerLabel')),
        ' / ',
      );
    }
    this._setConfigInitialFormEdit();
    this.setUpdatingSection();
    return matchingQuestions;
  }

  /**
   * Get Container Labels.
   * @param labelArray Label Array.
   * @returns Container Label array.
   */
  getContainerLabels(labelArray: StationContainerLabel[]): string {
    let containerLabel = '';
    const labelDataArray: StationContainerLabel[] = labelArray;

    if (
      labelDataArray.some((data: StationContainerLabel) => data.containerLabel)
    ) {
      containerLabel = _.join(
        _.compact(_.map(labelDataArray, 'containerLabel')),
        ' / ',
      );
    } else {
      containerLabel = this.termsGeneric.Container.Single + ' Name';
    }
    return containerLabel;
  }

  /**
   * Get matching Value.
   * @returns Matching FilteredValue.
   */
  getValueMatchingControl(): string[] {
    return this.form.controls['matchingFiltered'].value as [];
  }

  /**
   * Cleanup method.
   */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Remove the action from the parent.
   */
  removeParentAction(): void {
    this._action && this.actionToRemove.emit(this._action);
  }
}
