@if (timeZoneSettingFlag) {
  @for (trigger of scheduledTriggers(); track $index) {
    <section
      class="w-full flex pt-2 pb-4 justify-between items-center"
      [id]="trigger.rithmId"
      [attr.data-testid]="trigger.rithmId"
    >
      <!-- TRIGGER CONTENT. -->
      <div class="flex items-center space-x-4">
        <mat-slide-toggle
          id="toggle-scheduled-trigger"
          data-testid="toggle-scheduled-trigger"
          [checked]="!trigger.isDisabled"
          (change)="emitChangeTriggerStatus(trigger.rithmId, $event.checked)"
        />

        <span class="text-base text-gray-800 font-medium"
          >{{ readableScheduleTrigger(trigger) }},
          {{ readableScheduleTrigger(trigger, 2) }}
        </span>
        <span class="text-base text-gray-600 font-normal">{{
          currentTimeZone
        }}</span>
      </div>

      <button type="button" [matMenuTriggerFor]="settingsMenu">
        <i class="fal fa-ellipsis-h text-xl"></i>
      </button>

      <mat-menu
        #settingsMenu="matMenu"
        class="w-[360px] flex flex-col justify-center"
      >
        <button
          type="button"
          class="w-full h-[56px] px-3 text-left border-b border-gray-200"
          (click)="emitHandleTrigger(trigger, false)"
        >
          <span class="text-gray-800 text-sm font-medium">Edit trigger</span>
        </button>
        <button
          type="button"
          class="w-full h-[56px] px-3 text-left"
          (click)="emitHandleTrigger(trigger, true)"
        >
          <span class="text-gray-800 text-sm font-medium">Delete trigger</span>
        </button>
      </mat-menu>
    </section>
  }
} @else {
  @for (trigger of scheduledTriggers(); track $index) {
    <article class="trigger-item-row">
      <section class="flex items-center space-x-2">
        <p class="whitespace-nowrap">
          {{ readableScheduleTrigger(trigger) }},
          <span class="text-secondary-200">{{
            readableScheduleTrigger(trigger, 2)
          }}</span>
        </p>
        <div class="scheduled-triggers-action flex flex-row space-x-1">
          <button
            type="button"
            mat-icon-button
            [id]="'edit-scheduled-trigger-' + trigger.rithmId"
            [attr.data-testid]="'edit-scheduled-trigger-' + trigger.rithmId"
            class="text-lg"
            (click)="emitHandleTrigger(trigger, false)"
          >
            <i
              class="fa-light fa-edit text-accent-200 hover:text-accent-500 hover:cursor-pointer"
            ></i>
          </button>
          <button
            type="button"
            mat-icon-button
            [id]="'remove-scheduled-trigger-' + trigger.rithmId"
            [attr.data-testid]="'remove-scheduled-trigger-' + trigger.rithmId"
            class="text-lg remove-button"
            (click)="emitHandleTrigger(trigger, true)"
          >
            <i
              class="fa-light fa-trash text-error-200 hover:text-error-500 hover:cursor-pointer"
            ></i>
          </button>
        </div>
      </section>

      <!-- Toggle button.-->
      <div class="button-slide-section">
        <mat-slide-toggle
          [id]="'toggle-scheduled-trigger-' + trigger.rithmId"
          [attr.data-testid]="'toggle-scheduled-trigger-' + trigger.rithmId"
          [checked]="!trigger.isDisabled"
          (change)="emitChangeTriggerStatus(trigger.rithmId, $event.checked)"
        />
      </div>
    </article>
  }
}

<div class="w-[150px] pb-4">
  <button
    type="button"
    mat-stroked-button
    id="add-trigger"
    data-testid="add-trigger"
    (click)="emitHandleTrigger(undefined, false)"
  >
    <span class="font-semibold text-sky-500">ADD TRIGGER</span>
  </button>
</div>
