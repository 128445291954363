import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Subject } from 'rxjs';
import { PopupService } from 'src/app/core/popup.service';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { TermsGeneric, TriggersHelper } from 'src/helpers';
import {
  PowerTrigger,
  TriggerType,
  TriggerButton,
  PowerTriggerToUpdate,
  Question,
  QuestionFieldType,
  StationQueryParams,
} from 'src/models';
import { v4 as uuidv4 } from 'uuid';
import { FieldUpdateTriggerComponent } from 'src/app/station/rules/triggers/field-update-trigger/field-update-trigger.component';
import { ScheduledTriggerComponent } from 'src/app/station/rules/triggers/scheduled-trigger/scheduled-trigger.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import _, { Dictionary } from 'lodash';

/**
 * Component containing the triggers of the rules.
 */
@Component({
  selector: 'app-triggers',
  templateUrl: './triggers.component.html',
  styleUrls: ['./triggers.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    ScheduledTriggerComponent,
    FieldUpdateTriggerComponent,
    LoadingIndicatorComponent,
    MatTooltipModule,
  ],
  providers: [TriggersHelper],
  viewProviders: [MatExpansionPanel],
})
export class TriggersComponent implements OnDestroy, OnInit {
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** If the update field trigger must be shown. */
  @Input() showUpdateFieldTrigger = false;

  /** Triggers in progress. */
  @Input() ruleTriggers: PowerTrigger[] = [];

  /** Station trigger buttons. */
  stationButtons = input<TriggerButton[]>([]);

  /** Current station query parameters. */
  stationQueryParams = input<StationQueryParams | null>(null);

  /** Station RithmId. */
  @Input() stationRithmId!: string;

  /** Flow Button Name. */
  @Input() flowButtonName = '';

  /** General Triggers. */
  @Input() bucketQuestions: Question[] = [];

  /** Emitted when any trigger has been touched/updated. */
  @Output() updatingTriggers = new EventEmitter<void>();

  /** The modified/added trigger send back to flow. */
  @Output() handleScheduleTrigger = new EventEmitter<PowerTriggerToUpdate>();

  /** Move me to add custom Id. */
  @Output() standOutCustomId = new EventEmitter<void>();

  /** Contain if is valid field updated trigger or not. */
  @Output() isValidTrigger = new EventEmitter<boolean>();

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** List trigger type. */
  triggerType = TriggerType;

  /** Whether the ID for the current rule github trigger has been selected.*/
  githubIdSelected = '';

  /** Select github id. */
  selectGithubCustomId = false;

  /** Github Triggers. */
  githubTriggers = [
    {
      label: 'Pull request is merged',
      type: TriggerType.PullMerged,
    },
    {
      label: 'Pull request is created',
      type: TriggerType.PullCreated,
    },
    {
      label: 'Branch is created',
      type: TriggerType.BranchCreated,
    },
  ];

  /** Schedule trigger type list view if true. */
  scheduleTrigger = false;

  /** Field Update Triggers. */
  fieldUpdateTriggers: PowerTrigger[] = [];

  /** The date and time zone shown, if true. */
  showDateTimeZone = false;

  /** Whether the expansion button panel is expanded or not. */
  buttonPanelExpanded = false;

  /** Whether the expansion station panel is expanded or not. */
  stationPanelExpanded = false;

  /** Whether the expansion scheduled triggers panel is expanded or not. */
  scheduledPanelExpanded = false;

  /** Whether the expansion github triggers panel is expanded or not. */
  githubPanelExpanded = false;

  /** Whether the expansion of field triggers panel is expanded or not. */
  fieldPanelExpanded = false;

  /** A copy of rule triggers. */
  ruleTriggersCopy: PowerTrigger[] = [];

  /** Triggers id.. */
  triggersId: Dictionary<PowerTrigger> = {};

  /** Buttons id. */
  triggerButtonsId = '';

  /** Schedule trigger id. */
  scheduleTriggersId = signal<string>('');

  /** Station trigger id. */
  stationTriggersId = signal<string>('');

  /** Station trigger id. */
  githubTriggersId = signal<string>('');

  /** Field update trigger id. */
  fieldUpdatesTrigger = signal<string>('');

  /* The dynamic states to expand each mat expansion panel. */
  expansionPanelStates = {
    // The button triggers.
    buttonPanel: false,
    // The station triggers.
    stationPanel: false,
    // GitHub triggers.
    githubPanel: false,
    // Scheduled Triggers.
    scheduledPanel: false,
    // Field triggers.
    fieldPanel: false,
  };

  constructor(
    private popupService: PopupService,
    private triggersHelper: TriggersHelper,
  ) {}

  /**
   * On init method.
   */
  ngOnInit(): void {
    this.setTriggersId();
    this.buttonPanelExpanded = false;
    this.stationPanelExpanded = false;
    this.githubPanelExpanded = false;
    this.scheduledPanelExpanded = false;

    const ghTriggers = this.ruleTriggers.filter((t) =>
      this.triggersHelper.isGithubTrigger(t.type),
    );

    if (ghTriggers.length) {
      this.githubIdSelected = ghTriggers[0].source;
      this.selectGithubCustomId = true;
    }

    this.extractFieldUpdateTriggers();
    // We make A copy of rule triggers initially.
    this.ruleTriggersCopy = _.clone(this.ruleTriggers);
  }

  /** Set triggers id. */
  setTriggersId(): void {
    // Group triggers by type for easy access.
    this.triggersId = _.keyBy(this.ruleTriggers, 'type');
    // Helper function to filter and join IDs into a single string.
    const getIdsString = (ids: (string | undefined)[]) =>
      ids.filter((id) => id).join('/');
    // Set trigger IDs for each category.
    this.scheduleTriggersId.set(
      getIdsString(this.scheduledTriggers.map((schedule) => schedule.rithmId)),
    );
    this.stationTriggersId.set(
      getIdsString([
        this.triggersId[TriggerType.ContainerCreated]?.rithmId,
        this.triggersId[TriggerType.ContainerArrived]?.rithmId,
        this.triggersId[TriggerType.AnyContainerArrived]?.rithmId,
      ]),
    );
    this.githubTriggersId.set(
      getIdsString([
        this.triggersId[TriggerType.PullMerged]?.rithmId,
        this.triggersId[TriggerType.PullCreated]?.rithmId,
        this.triggersId[TriggerType.BranchCreated]?.rithmId,
      ]),
    );
    this.triggerButtonsId =
      [
        ...(this.stationButtons() || []).map((button) => button.rithmId),
        this.triggersId[TriggerType.ManualFlow]?.rithmId,
        this.triggersId[TriggerType.Save]?.rithmId,
        this.triggersId[TriggerType.Button]?.rithmId,
      ]
        .filter((id) => id)
        .join('/') || '';
    const buttonsIds = this.triggerButtonsId.split('/');
    // Get the `elementId` from query parameters and update panel states.
    const elementId = this.stationQueryParams()?.elementId;
    if (elementId) {
      const scheduleIds = this.scheduleTriggersId().split('/');
      const stationIds = this.stationTriggersId().split('/');
      const githubIds = this.githubTriggersId().split('/');
      // Update panel states dynamically.
      this.expansionPanelStates = {
        ...this.expansionPanelStates,
        buttonPanel: buttonsIds.includes(elementId),
        stationPanel: stationIds.includes(elementId),
        githubPanel: githubIds.includes(elementId),
        scheduledPanel: scheduleIds.includes(elementId),
      };
    }
  }

  /**
   * Filter received Bucket Questions with CustomId type.
   * @returns An array of questions.
   */
  get customIdComponents(): Question[] {
    const idBucketComponents = this.bucketQuestions.filter(
      (q) => q.questionType === QuestionFieldType.CustomId,
    );
    return idBucketComponents;
  }

  /**
   * Returns the list of triggers of the type scheduled in the rules.
   * @returns List of triggers of type scheduled triggers.
   */
  get scheduledTriggers(): PowerTrigger[] {
    return this.ruleTriggers.filter(
      (trigger) => trigger.type === TriggerType.CronExpression,
    );
  }

  /**
   * Returns the number of existing button triggers in the station.
   * @returns A number.
   */
  get buttonTriggerNumber(): number {
    const triggers = this.ruleTriggers;

    const isEnabled = (trigger: PowerTrigger) => trigger && !trigger.isDisabled;

    const buttonTriggersEnabled = triggers.reduce((count, trigger) => {
      if (
        [TriggerType.ManualFlow, TriggerType.Save, TriggerType.Button].includes(
          trigger.type,
        )
      ) {
        count += isEnabled(trigger) ? 1 : 0;
      }
      return count;
    }, 0);

    return buttonTriggersEnabled;
  }

  /**
   * Returns the number existing of type station triggers.
   * @returns A number.
   */
  get stationTriggerNumber(): number {
    let stationNumber = 0;
    const triggers = this.ruleTriggers;
    stationNumber = triggers.filter((trigger) =>
      this.triggersHelper.isStationTrigger(trigger.type),
    ).length;
    return stationNumber;
  }

  /**
   * Returns the number existing of type github triggers.
   * @returns A number.
   */
  get githubTriggerNumber(): number {
    let githubNumber = 0;
    const triggers = this.ruleTriggers;
    githubNumber = triggers.filter((trigger) =>
      this.triggersHelper.isGithubTrigger(trigger.type),
    ).length;
    return githubNumber;
  }

  /**
   * Returns the number of existing schedule triggers in the station.
   * @returns A number.
   */
  get scheduleTriggerNumber(): number {
    const scheduleRulAct = this.scheduledTriggers.filter(
      (sch) => !sch.isDisabled,
    );
    return scheduleRulAct.length;
  }

  /**
   * Returns the number of existing field triggers in the station.
   * @returns A number.
   */
  get fieldTriggerNumber(): number {
    const totalFieldTriggers = this.ruleTriggers.filter(
      ({ type }) => type === TriggerType.FieldUpdated,
    ).length;
    return totalFieldTriggers;
  }

  /**
   * Get field update trigger from rules.
   */
  extractFieldUpdateTriggers(): void {
    this.fieldUpdateTriggers = this.ruleTriggers.filter(
      ({ type }) => type === TriggerType.FieldUpdated,
    );
    const fieldTriggersIds = this.fieldUpdateTriggers.map(
      (field) => field.rithmId,
    );
    this.fieldUpdatesTrigger.set(fieldTriggersIds.join('/'));
    const elementId = this.stationQueryParams()?.elementId;
    if (elementId && fieldTriggersIds.includes(elementId)) {
      this.expansionPanelStates['fieldPanel'] = true;
    }
  }

  /**
   * Check whether the trigger buttons are already loaded or not.
   * @returns A boolean.
   */
  get loadedButtonTriggers(): boolean {
    return this.stationButtons() && this.flowButtonName.length ? true : false;
  }

  /**
   * Verify that the triggers exist on the preloaded rule.
   * @param triggerType The type of trigger to look for in the current rule.
   * @param rithmId Trigger RithmId - optional.
   * @returns A boolean.
   */
  checkedTrigger(triggerType: TriggerType, rithmId?: string): boolean {
    /** Will contain the trigger in case it exists. */
    let triggerFound;
    const triggers = this.ruleTriggers;

    if (triggerType === TriggerType.Button) {
      // In case it is a button, it must be searched by its value.
      triggerFound = triggers.find((element) => element.value === rithmId);
    } else {
      triggerFound = triggers.find((element) => element.type === triggerType);
    }

    //Validate if the trigger exists, if not return false.
    return triggerFound ? !triggerFound.isDisabled : false;
  }

  /**
   * Handle the selected trigger and issues the change to edit it in the current power.
   * @param triggerType The type of trigger selected.
   * @param eventToggle Whether checked or not.
   * @param triggerData The trigger data could be type a value of button or scheduled (optional).
   * @param handleToggle Is used in case to field update that contain both changes remove/disable(optional).
   * @param isValidChange Is used in case to field update if contain a valid change.
   */
  handleTrigger(
    triggerType: TriggerType,
    eventToggle: boolean,
    triggerData = '',
    handleToggle = false,
    isValidChange = false,
  ): void {
    /** The triggers in progress. */
    const triggers: PowerTrigger[] = this.ruleTriggers;
    let scope: 'toggle' | 'add' | 'remove' = 'toggle';

    /** New trigger by default. */
    const triggerDefaultCore: PowerTrigger = {
      rithmId: uuidv4(),
      type: triggerType,
      source: this.triggersHelper.isGithubTrigger(triggerType)
        ? this.githubIdSelected
        : '',
      value: triggerData,
      startDateUTC: new Date().toJSON(),
      endDateUTC:
        triggerType === TriggerType.CronExpression ? new Date().toJSON() : null,
      settings: '',
      isDisabled: false,
    };

    /**
     * Triggers to be disabled instead of removed.
     */
    const triggersToDisable = [
      TriggerType.ManualFlow,
      TriggerType.Save,
      TriggerType.CronExpression,
      TriggerType.Button,
    ].includes(triggerType);

    const targetTrigger = triggers.find((trigger) =>
      triggerType === TriggerType.Button
        ? trigger.value === triggerData
        : [TriggerType.CronExpression, TriggerType.FieldUpdated].includes(
              triggerType,
            )
          ? trigger.rithmId === triggerData
          : trigger.type === triggerType,
    );

    if (targetTrigger) {
      /** If the selected trigger is included in the triggers group to be disabled, its isDisabled property will be modified. */
      if (triggersToDisable || handleToggle) {
        if (
          !eventToggle &&
          [TriggerType.Button, TriggerType.Save].includes(triggerType)
        ) {
          // We find it in the copy and verify its existence.
          const findTrigger = this.ruleTriggersCopy.find(
            (element) => element.value === targetTrigger.value,
          );
          // If it does not exist, we remove it.
          if (!findTrigger) {
            triggers.splice(
              triggers.findIndex((tr) => tr.rithmId === targetTrigger.rithmId),
              1,
            );
          }
        }
        targetTrigger.isDisabled = !eventToggle;
      } else {
        /** This condition will apply for cases such as: GithubTriggers, StationTriggers. */
        eventToggle && triggerType !== TriggerType.FieldUpdated
          ? triggers.push(triggerDefaultCore)
          : triggers.splice(
              triggers.findIndex((tr) =>
                triggerType !== TriggerType.FieldUpdated
                  ? tr.type === triggerType
                  : tr.type === triggerType &&
                    tr.rithmId === targetTrigger.rithmId,
              ),
              1,
            );
        scope = 'remove';
      }
    } else {
      triggers.push(triggerDefaultCore);
      scope = 'add';
    }

    triggerType !== TriggerType.FieldUpdated
      ? this.updatingTriggers.emit()
      : this.reviewChangeInTrigger(isValidChange, scope);
  }

  /**
   * Add question to trigger.
   * @param questionSelected Question selected.
   * @param triggerSelected Trigger selected.
   * @param triggerType Trigger type selected.
   * @param isValidChange If is valid change.
   */
  updateValueToTrigger(
    questionSelected: string,
    triggerSelected: string,
    triggerType = TriggerType.FieldUpdated,
    isValidChange = false,
  ): void {
    this.ruleTriggers.map((trigger) => {
      if (trigger.rithmId === triggerSelected && trigger.type === triggerType)
        trigger.source = questionSelected;
    });
    this.isValidTrigger.emit(isValidChange);
  }

  /**
   * Review changes to the trigger.
   * @param isValidChange Contains if change is valid or not.
   * @param scope Action.
   */
  reviewChangeInTrigger(
    isValidChange: boolean,
    scope: 'remove' | 'add' | 'toggle',
  ): void {
    if (scope !== 'toggle') {
      this.extractFieldUpdateTriggers();
      isValidChange =
        isValidChange === false
          ? isValidChange
          : !this.fieldUpdateTriggers.some(({ source }) => !source.length);
    }
    this.isValidTrigger.emit(isValidChange);
  }

  /**
   * Remove selected trigger confirmation.
   * @param triggerSelected Trigger selected.
   */
  async removeScheduledTrigger(triggerSelected: PowerTrigger): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Are you sure?',
      message: `You will remove this ${TermsGeneric.Station.Single.toLowerCase()} trigger.`,
      okButtonText: 'Remove',
      important: true,
    });
    if (confirm) {
      this.handleScheduleTrigger.emit({
        trigger: triggerSelected,
        removeTrigger: true,
      });
    }
  }

  /**
   * Set the ID to every gh trigger enabled/added.
   * @param id CustomID selected.
   */
  setGithubTriggerId(id: string): void {
    this.ruleTriggers.forEach((t) => {
      if (this.triggersHelper.isGithubTrigger(t.type)) {
        t.source = id;
        this.updatingTriggers.emit();
      }
    });
  }

  /**
   * Add custom id if there are not id.
   */
  addCustomId(): void {
    this.selectGithubCustomId = this.customIdComponents.length > 0;
    this.standOutCustomId.emit();
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
